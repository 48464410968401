import React, { useEffect, useState } from 'react';

import './App.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, ComposedChart, ReferenceLine, Label } from 'recharts';
import moment from 'moment'


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{payload[0].name} at {new Date(label*1000).toISOString()}</p>
        <p>{parseFloat(payload[0].value).toFixed(3)}s</p>
      </div>
    );
  }

  return null;
};

function startAt(data, start) {
  let result = [];
  for (const item of data) {
    if (item.date >= start) {
      result.push(item);
    }
  }
  return result;
}

function Chart(response, isHttpPkgs) {
  let lines = <>
    <Line type="monotone" dataKey="Cloudflare Workers" stroke="#f29422" dot={false} strokeWidth={2} />
    <Line type="monotone" dataKey="Cloudflare Rust Workers" stroke="#d15106" dot={false} strokeWidth={2} />
    <Line type="monotone" dataKey="AWS Lambda" stroke="#146eb4" dot={false} strokeWidth={2} />
  </>;

  if (isHttpPkgs) {
    lines = <>
      <Line type="monotone" dataKey="AWS Lambda HTTP Pkgs" stroke="#146eb4" dot={false} strokeWidth={2} />
      <Line type="monotone" dataKey="Cloudflare Workers HTTP Pkgs" stroke="#f29422" dot={false} strokeWidth={2} />
    </>
  }


  return (
    <ComposedChart
    width={1280}
    height={960}
    data={startAt(response, isHttpPkgs ? 1727359597 : 1727717899)}
    margin={{
      top: 5,
      right: 30,
      left: 80,
      bottom: 40,
    }}
  >
    {/* <CartesianGrid strokeDasharray="3 3" /> */}
    <XAxis dataKey="date"  tickFormatter={(unixTime) => moment.unix(parseFloat(unixTime)).format('YYYY-MM-DD')} scale="time" style={{ fontSize: "1.5rem" }}>
      <Label
            style={{
                textAnchor: "middle",
                fontSize: "100%",
                marginRight: "2pt"
            }}
          dy={40}
          value={"Date"} />
    </XAxis>
    <YAxis style={{ fontSize: "1.5rem" }}>
      <Label
          style={{
              textAnchor: "middle",
              fontSize: "100%",
              marginRight: "2pt"
          }}
        dx={-80}
        angle={270}
        value={"Cold Start (secs)"} />
    </YAxis>
    {lines}

    {/* @ts-ignore */}
    <Legend verticalAlign="top" layout="radial" align="right"/>
    <Tooltip content={<CustomTooltip />} />
  </ComposedChart>);
}


function App() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const dummy = [{"date":"1718556080","Cloudflare Workers":"1.306999921798706"},{"date":"1720506126","AWS Lambda":"0.13899998664855957"},{"date":"1720506120","Cloudflare Workers":"0.6329999446868896"},{"date":"1720506320","Cloudflare Workers":"0.8329999446868896"},{"date":"1719770506","AWS Lambda":"0.379000186920166"},{"date":"1720506326","AWS Lambda":"0.33899998664855957"}];

  const [response, setResponse] = useState(null);
  const [httpPkgsResponse, setHttpPkgsResponse] = useState(null);

  useEffect(
    () => {
      fetchData();
    },
    []
  )


  function fetchData() {
    fetch("https://api.cold.picheta.me/list.json")
      .then(res => res.json())
      .then(
        (response) => {
          setIsLoaded(true);
          setResponse(response);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

    fetch("https://api.cold.picheta.me/list_http_pkgs.json")
      .then(res => res.json())
      .then(
        (response) => {
          setHttpPkgsResponse(response);
        },
        (error) => {
          setError(error);
        }
      )
  }

  let content = null;

  if (error) {
    content = (
      <>
        <h1 className="text-xl text-center">We had a problem</h1>
        <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem..</p>
      </>
    );
  } else if (!isLoaded) {
    content = (<p>Loading...</p>);
  } else if (response == null) {
    content = (
      <>
        <h1 className="text-xl text-center">Couldn't find results this time</h1>
        <p className="text-sm text-center">Try again later.</p>
      </>
    )
  } else {

    const rows = response.map((val) => {
      if (val["Cloudflare Workers"] === undefined) {
        return null;
      }
      return (
        <tr>
          <td>{new Date(val.date*1000).toISOString()}</td>
          <td>{Number(val["Cloudflare Workers"]).toFixed(2)}</td>
        </tr>
      );
    });

    const pkgsRows = httpPkgsResponse.map((val) => {
      if (val["Cloudflare Workers HTTP Pkgs"] === undefined) {
        return null;
      }
      return (
        <tr>
          <td>{new Date(val.date*1000).toISOString()}</td>
          <td>{Number(val["Cloudflare Workers HTTP Pkgs"]).toFixed(2)}</td>
        </tr>
      );
    });

    content = (<>
        <h4>Cold start of a Hello World Python script</h4>
        <div style={{height: "960px"}}>
          {Chart(response, false)}
        </div>
        <div style={{maxHeight: "250px", overflow: "scroll", marginTop: "4rem", fontSize:"80%"}}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Cold Start (secs)</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>

        <h4>Cold start of a Python script which imports fastapi, httpx, pydantic</h4>
        <div style={{height: "960px"}}>
          {Chart(httpPkgsResponse, true)}
        </div>

        <div style={{maxHeight: "250px", overflow: "scroll", marginTop: "4rem", fontSize:"80%"}}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Cold Start (secs)</th>
              </tr>
            </thead>
            <tbody>
              {pkgsRows}
            </tbody>
          </table>
        </div>
      </>
    );
  }


  return (
    <div className="App">
      <header className="App-header">

        {content}
      </header>
    </div>
  );
}

export default App;
